<template>
  <div>
    <span v-if="localSheetLink" class="link" @click="openSheetLink">
      <mf-icon fill color="primary" size="20" class="mr-2">description</mf-icon>Ver {{ label }}
    </span>
    <span v-else-if="loading">
      <v-progress-circular indeterminate color="primary" size="24" class="ml-2"></v-progress-circular>
    </span>
    <span v-else class="link" @click="createSheetLink"> <mf-icon fill color="primary" size="20" class="mr-2">post_add</mf-icon>Criar {{ label }} </span>
  </div>
</template>

<script>
import { MUTATION_CREATE_CLIENT_SHEET } from '@/modules/retailers/graphql'
export default {
  name: 'SheetLink',
  props: {
    clientId: {
      type: String,
      required: true
    },
    sheetLink: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    sheetTemplateType: {
      type: String,
      required: true
    }
  },
  data: () => ({
    localSheetLink: '',
    loading: false
  }),
  watch: {
    sheetLink(val) {
      this.localSheetLink = val
    }
  },
  mounted() {
    this.localSheetLink = this.sheetLink
  },
  methods: {
    async createSheetLink() {
      this.loading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_CREATE_CLIENT_SHEET,
          variables: {
            client_id: this.clientId,
            sheet_template_type: this.sheetTemplateType
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })
        this.localSheetLink = data.createClientSheet
        window.open(this.localSheetLink, '_blank')
      } catch (e) {
        this.$snackbar({ message: 'Erro ao criar planilha', snackbarColor: 'error' })
      }
      this.loading = false
    },
    openSheetLink() {
      window.open(this.localSheetLink, '_blank')
    }
  }
}
</script>
